<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formEstoque" lazy-validation>
      <v-container fluid>
        <v-widget title="Movimentação de Estoque" :temCampoObrigatorio="true">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <buscar-produto persistent-placeholder outlined v-model="movimento.produto" @selecionarProduto="selecionarProduto" :rules="[rules.obrigatorio]"></buscar-produto>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Descrição do Produto *" persistent-placeholder outlined v-model="produto.descricao" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Data *" persistent-placeholder outlined v-model="movimento.data"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor
                  label="Quantidade"
                  ref="quantidade"
                  persistent-placeholder
                  outlined
                  v-model="movimento.quantidade"
                  :decimais="2"
                  @keypress.native.enter="$refs.tipo.focus()"
                  :rules="[rules.obrigatorio, rules.valorMaiorQueZero]"
                ></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete label="Tipo *" ref="tipo" persistent-placeholder outlined v-model="movimento.tipo" :items="['Entrada', 'Saída']" @keypress.native.enter="$refs.numero_nota.focus()" :rules="[rules.obrigatorio]"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Nota Fiscal" ref="numero_nota" persistent-placeholder outlined v-model="movimento.numero_nota" @keypress.native.enter="$refs.valor_compra.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Valor de Compra" ref="valor_compra" persistent-placeholder outlined v-model="movimento.valor_compra" :decimais="2" :rules="[rules.numeroValido]" @keypress.native.enter="$refs.valor_venda.focus()"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Valor de Venda" ref="valor_venda" persistent-placeholder outlined v-model="movimento.valor_venda" :decimais="2" :rules="[rules.numeroValido]" @keypress.native.enter="$refs.responsavel.focus()"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Responsável" ref="responsavel" persistent-placeholder outlined v-model="movimento.responsavel" @keypress.native.enter="$refs.observacao.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea label="Observação" ref="observacao" persistent-placeholder outlined v-model="movimento.observacao" @keypress.native.enter="salvar"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import validador from '@/util/validador';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'FormularioEstoqueSimples',

  data: () => ({
    valid: false,
    validarData: [(v) => !v || (v && validador.dataNascimento(v)) || 'Data Inválida'],
    validarValor: [(v) => !v || (v && v.match('^[0-9]+(\\,[0-9]{1,2})?$') !== null) || 'Valor Inválido'],
    codigoProduto: '',
    constantes: constantes,
    loading: false,
    rules: { ...regras },
  }),

  async created() {
    try {
      this.loading = true;
      const movimento = this.$store.state.estoque.movimentoAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!movimento._id || movimento._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione uma movimentação para editar');
        this.$router.push({ name: 'estoqueSimples' });
      }

      if (!estaEditando) {
        this.$store.commit('estoque/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    selecionarProduto(produto) {
      this.$store.commit('estoque/setProduto', produto);

      setTimeout(() => {
        this.$refs.quantidade.focus();
      }, 300);
    },

    async salvar() {
      if (this.$refs.formEstoque.validate()) {
        try {
          await this.$store.dispatch('estoque/salvar');
          this.$router.push({ name: 'estoqueSimples' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$store.commit('estoque/estadoInicial');
      this.$router.push({ name: 'estoqueSimples' });
    },
  },

  computed: {
    ...mapState('estoque', {
      movimento: 'movimentoAtual',
      produto: 'produto',
      carregando: 'carregando',
    }),

    ...mapGetters('estoque', {
      estaEditando: 'estaEditando',
      // textoBtnConfirmar: 'textoBtnConfirmar',
    }),
  },
};
</script>
